import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AdminHead from '../../../containers/Universal/AdminHead/AdminHead';
import { apiServices, apiServicesCategory } from '../../../axios';
import Table from '../../../components/TabelData/TableData';
import { MdDelete } from 'react-icons/md';
import { FaPen } from 'react-icons/fa';
import Loader from '../../../components/Loader/Loader';
import FilterFields from '../../../components/FilterFields/FilterFields';
import { alertForConfirmRequest, getPagination } from '../../../utils/helpers';
import { useModal } from '../../../components/Modals';
import {
    adminServicesPost,
    adminServicesUpdate,
    adminServicesDelete,
} from '../../../axios/admin/manageServices';

const AdminServices = () => {
    const dispatchModal = useModal();
    const [services, setServices] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [serviceCategories, setServicesCategories] = useState([]);
    const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
    console.log(services);

    const getData = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiServices.getServices(paramsObj);
                const count = page === 1 ? await apiServices.getServicesCount(paramsObj) : 1;
                setPagination((prev) => (page === 1 ? getPagination(count, limit) : prev));
                if (result.length === 0 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                    return;
                }
                setServices(result);
            } catch (error) {
                console.log(error);
            }
        },
        [limit, page]
    );

    const getServicesCategories = useCallback(async () => {
        try {
            const result = await apiServicesCategory.getServicesCategories({});
            setServicesCategories(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminServicesPost(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminServicesDelete({
                id: id,
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminServicesUpdate(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal('createUpdateServices', {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: limit,
            search: search,
            filters: { category: selectedServiceCategory || null },
        });
    }, [getData, page, search, limit, selectedServiceCategory]);

    useEffect(() => {
        getServicesCategories();
    }, [getServicesCategories]);

    const columns = useMemo(
        () => [
            {
                key: 'serviceName',
                children: 'Назва',
            },
            {
                key: 'price',
                children: 'Ціна',
            },
            {
                key: 'paidService',
                children: 'Платність',
            },
            {
                key: 'category',
                children: 'Категорія',
            },
            {
                key: 'settings',
                children: 'Керування',
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            services?.map((itm) => ({
                rowData: [
                    {
                        key: 'serviceName',
                        children: itm?.serviceName,
                    },
                    {
                        key: 'price',
                        children: itm?.price,
                    },
                    {
                        key: 'paidService',
                        children: itm?.paidService ? 'Так' : 'Ні',
                    },
                    {
                        key: 'category',
                        children: itm?.namesCategories?.map((category) => category).join(', '),
                    },
                    {
                        key: 'setting',
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            serviceName: itm?.serviceName,
                                            price: itm?.price,
                                            paidService: itm?.paidService,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() => alertForConfirmRequest(deleteHandler, itm?._id)}
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [services, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead title={'Послуги'} modalAction={createOrUpdate} />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                    setSelectedServiceCategory={setSelectedServiceCategory}
                    servicesCategoriesData={serviceCategories}
                />
                {services?.length !== 0 ? (
                    <Table
                        rows={dataRows}
                        cols={columns}
                        pagination={{
                            count: pagination.length,
                            setPage: setPage,
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminServices;
