import React from 'react';

const TabsServicesContent = ({ services }) => {
    return (
        <div className="flex flex-col gap-2">
            {services?.map((service) => {
                return (
                    <div
                        className="flex gap-3 items-center justify-between shadow-lg px-4 py-2 rounded-md"
                        key={service?._id}
                    >
                        <div className="flex flex-col flex-1 ">
                            <div className="flex justify-between items-center gap-6">
                                <p className="font-montserrat font-medium text-base text-curious-blue-500">
                                    {service?.serviceName}
                                </p>
                            </div>
                        </div>
                        <p className="font-montserrat font-medium text-base text-curious-blue-700">
                            {service?.price || 'Безкоштовно'}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default TabsServicesContent;
