import React, { useCallback, useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { apiDevisions } from '../../axios';
import logo from '../../image/logo.png';
import { FaMapLocationDot } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import navigation from '../../data/navigation';
import { Link } from 'react-router-dom';
import SocialBtn from '../SocialBtn/SocialBtn';
import BlockCircles from '../BlockCircles/BlockCircles';
import logoBs from '../../image/logoBs.svg';

const Footer = () => {
    const [devisions, setDevisions] = useState([]);

    const getData = useCallback(async (objectParams) => {
        try {
            const result = await apiDevisions.getDevisions(objectParams);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({});
    }, [getData]);

    return (
        <footer className={styles.footer_wrapper}>
            <BlockCircles position="left" />
            <div className={styles.footer_content}>
                <div className={styles.footer_contact}>
                    <div className={styles.footer_logo_box}>
                        <img src={logo} alt="Клініка Д.Прокопової" />
                    </div>
                    <div className={styles.footer_contact_box}>
                        {devisions?.map((devision) => {
                            return (
                                <div className={styles.footer_adress} key={devision?._id}>
                                    <FaMapLocationDot />
                                    <p>{devision?.location?.adress}</p>
                                </div>
                            );
                        })}
                        <div className={styles.footer_email}>
                            <MdEmail />
                            <a href={`mailto:${devisions[0]?.email}`}>{devisions[0]?.email}</a>
                        </div>
                        <div className={styles.footer_phone}>
                            <FaPhoneSquareAlt />
                            <a href={`tel:${devisions[0]?.phone?.mobile}`}>
                                {devisions[0]?.phone?.mobile}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.footer_navitagion}>
                    <p className={styles.footer_navigation_title}>Навігація</p>
                    <nav className={styles.footer_navigation_list_box}>
                        <ul className={styles.footer_navigation_list}>
                            {navigation?.map((link, index) => (
                                <Link to={link?.href} key={index}>
                                    {link?.linkName}
                                </Link>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className={styles.footer_schedule}>
                    <p className={styles.footer_navigation_title}>Графік роботи</p>
                    <div className={styles.footer_schedule_box}>
                        <p>
                            Будні<span>{devisions[0]?.schedule?.weekdays}</span>
                        </p>
                        <p>
                            Субота<span>{devisions[0]?.schedule?.saturday}</span>
                        </p>
                        <p>
                            Неділя<span>{devisions[0]?.schedule?.sunday}</span>
                        </p>
                    </div>
                    {devisions && (
                        <div className={styles.footer_socila_buttons}>
                            <SocialBtn devision={devisions[0]} />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.footer_private_polici}>
                <p>D.PROKOPOVA | Усі права захищенні © 2024-2025</p>
                <a
                    href="https://borys-shahiiev.website/ua"
                    target="_blank"
                    rel="noreferrer"
                    className="flex gap-1 justify-center  items-center  hover:text-per-yellow-primary transition-all duration-300"
                >
                    Розробка та підтримка сайту:{' '}
                    <img className="max-h-[40px] fill-slate-900" src={logoBs} alt="Борис Шагієв" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
