import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { apiSpecialties } from '../../axios';

const DevisionCard = ({ devision, showSpec = true }) => {
    const [specialties, setSpecialties] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({
            filters: {
                devision: devision?._id,
            },
        });
    }, [getData, devision?._id]);

    return (
        <div className="shadow-mt p-3 rounded-xl w-full flex justify-center gap-3 flex-col relative">
            <Link to={`/devisions/${devision?._id}`}>
                <p className=" font-montserrat text-curious-blue-800 text-center font-semibold text-xl">
                    {devision?.nameDevision}
                </p>
            </Link>
            <Divider variant="middle" />
            <div className="flex gap-3 md:flex-col md:justify-center md:items-center">
                <Link to={`/devisions/${devision?._id}`} className=" w-full max-w-[250px]">
                    <p className="mb-2 font-semibold font-montserrat text-md text-curious-blue-600">
                        Графік роботи:
                    </p>
                    <p className=" font-montserrat flex justify-between w-full border-b-2 pb-1 mb-1 border-curious-blue-600 text-curious-blue-800">
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            ПН-ПТ:{' '}
                        </span>
                        {devision?.schedule?.weekdays}
                    </p>
                    <p className=" font-montserrat flex justify-between w-full border-b-2 pb-1 mb-1 border-curious-blue-600 text-curious-blue-800">
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            СБ:{' '}
                        </span>
                        {devision?.schedule?.saturday}
                    </p>
                    <p className=" font-montserrat flex justify-between w-full border-b-2 pb-1 mb-1 border-curious-blue-600 text-curious-blue-800">
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            НД:{' '}
                        </span>
                        {devision?.schedule?.sunday}
                    </p>
                </Link>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div className=" flex flex-col md:justify-center md:max-w-[250px]">
                    <p className="mb-2 font-semibold font-montserrat md:text-center text-md text-curious-blue-600">
                        Контакти та адреса:
                    </p>
                    <a
                        href={`tel:${devision?.phone?.mobile}`}
                        className=" font-montserrat text-curious-blue-600"
                    >
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            Телефон:{' '}
                        </span>
                        {devision?.phone?.mobile}
                    </a>
                    <p className=" font-montserrat text-curious-blue-800">
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            Адреса:{' '}
                        </span>
                        {devision?.location?.adress}
                    </p>
                    <p className=" font-montserrat text-curious-blue-800">
                        <span className=" font-montserrat text-curious-blue-600 font-semibold">
                            Email:{' '}
                        </span>
                        <a href={`mailto:${devision?.email}`}>{devision?.email}</a>
                    </p>
                </div>
            </div>
            {showSpec && (
                <>
                    <Divider variant="middle" />
                    <div className="flex flex-wrap gap-1">
                        <p className="font-semibold font-montserrat text-curious-blue-600 text-sm">
                            Спеціалісти:{' '}
                        </p>
                        {specialties?.map((itm) => (
                            <p className="text-sm" key={itm?._id}>
                                {itm?.specialty} <span className=" text-curious-blue-600"> / </span>
                            </p>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default DevisionCard;
