import axios from 'axios';
import { PATH } from '../data/apiPath';
import { clearEmptyProperty } from '../utils/helpers';

const getServiceCategory = async (id = null) => {
    return await new Promise((resolve, reject) => {
        axios
            .get(`${PATH}/api/servicesCategories/${id}`)
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

const getServicesCategories = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let {
            limit = 100,
            page = 1,
            filters = {},
            search = '',
        } = rules && rules !== null ? rules : {};
        params.append('limit', limit);
        params.append('page', page);
        if (search && search !== '') params.append('search', search);
        const clearFilters = clearEmptyProperty(filters);
        if (Object.keys(clearFilters).length > 0)
            params.append('filters', JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/servicesCategories`, {
                params,
            })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

const getServicesCategoriesCount = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let { filters = {}, search = '' } = rules && rules !== null ? rules : {};
        if (search && search !== '') params.append('search', search);
        const clearFilters = clearEmptyProperty(filters);
        if (Object.keys(clearFilters).length > 0)
            params.append('filters', JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/servicesCategoriesCount`, {
                params,
            })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

export { getServiceCategory, getServicesCategories, getServicesCategoriesCount };
