import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import FixedContactMenu from '../FixedContactMenu/FixedContactMenu';

const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            <FixedContactMenu />
        </>
    );
};

export default Layout;
