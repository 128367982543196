import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { MAPKEY } from '../../data/apiPath';
import mapStyle from './MapStyles';

const DevisionMap = ({ devisions }) => {
    const libraries = ['places'];

    const mapContainerStyle = {
        width: '100vw',
        height: '70vh',
    };

    const center = { lat: 48.683981, lng: 26.58809 };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: MAPKEY,
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    const openGoogleMaps = (latit, langit) => {
        const navigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${latit},${langit}`;
        window.open(navigationUrl, '_blank');
    };

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={center}
                options={{ styles: mapStyle }}
            >
                {devisions.map((devision) => (
                    <Marker
                        key={devision?._id}
                        onClick={() => openGoogleMaps(devision?.location.lt, devision?.location.ln)}
                        position={{
                            lat: Number(devision?.location.lt),
                            lng: Number(devision?.location.ln),
                        }}
                        title={devision?.nameDevision}
                    />
                ))}
            </GoogleMap>
        </div>
    );
};

export default DevisionMap;
