import React, { useCallback, useEffect, useState } from 'react';
import { FaPhoneVolume } from 'react-icons/fa6';
import { FaFacebook } from 'react-icons/fa';
import { FaViber } from 'react-icons/fa';
import { SiTelegram } from 'react-icons/si';
import { AiFillInstagram } from 'react-icons/ai';
import { apiDevisions } from '../../axios';

const FixedContactMenu = () => {
    const [open, setOpen] = useState(false);

    const [devisions, setDevisions] = useState([]);

    const getData = useCallback(async (objectParams) => {
        try {
            const result = await apiDevisions.getDevisions(objectParams);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        getData({});
    }, [getData]);

    return (
        <>
            <div
                className=" fixed animate-pulse bottom-5 right-5 z-[100] cursor-pointer p-4 rounded-full border-4 border-curious-blue-700 bg-curious-blue-700"
                onClick={() => setOpen((prev) => !prev)}
            >
                <FaPhoneVolume className="text-3xl text-white " />
            </div>
            {open && (
                <div
                    className={`${
                        open ? 'bottom-[100px] opacity-100' : '-bottom-[100px] opacity-0'
                    } transition-all duration-200 bg-white rounded-xl fixed flex flex-col items-center gap-3 right-[24px] shadow-xl p-3 z-[100] border-2 border-curious-blue-700`}
                >
                    {devisions[0]?.phone?.mobile && (
                        <a
                            href={`tel:${devisions[0]?.phone?.mobile}`}
                            className="text-3xl text-green-600"
                            alt="Facebook"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaPhoneVolume />
                        </a>
                    )}
                    {devisions[0]?.social?.facebook && (
                        <a
                            href={devisions[0]?.social?.facebook}
                            className="text-4xl text-blue-600"
                            alt="Facebook"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaFacebook />
                        </a>
                    )}
                    {devisions[0]?.social?.telegram && (
                        <a
                            href={devisions[0]?.social?.telegram}
                            className="text-4xl"
                            alt="Telegram"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SiTelegram />
                        </a>
                    )}
                    {devisions[0]?.social?.instagram && (
                        <a
                            href={devisions[0]?.social?.instagram}
                            className="rounded-lg text-4xl bg-gradient-to-r from-[#F58529] via-[#DD2A7B] to-[#515BD4] text-white"
                            alt="Instagram"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AiFillInstagram />
                        </a>
                    )}
                    {devisions[0]?.social?.viber && (
                        <a
                            href={devisions[0]?.social?.viber}
                            className="text-4xl text-purple-600"
                            alt="Viber"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaViber />
                        </a>
                    )}
                </div>
            )}
        </>
    );
};

export default FixedContactMenu;
