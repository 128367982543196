import React, { useState } from 'react';
import styles from './BurgerMenu.module.scss';
import { Drawer } from 'antd';
import navigation from '../../data/navigation';
import logo from '../../image/logo.png';
import { Link } from 'react-router-dom';
import { IoMdCloseCircle } from 'react-icons/io';
import { FaSquarePhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import SocialBtn from '../SocialBtn/SocialBtn';

const BurgerMenu = ({ devision }) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={styles.burger_btn} onClick={showDrawer}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <Drawer
                title={
                    <div className={styles.drawer_header}>
                        <button className={styles.drawer_close} onClick={closeDrawer}>
                            <IoMdCloseCircle />
                        </button>
                        <img className={styles.drawer_logo} src={logo} alt="Logo" />
                    </div>
                }
                placement="top"
                onClose={closeDrawer}
                className={styles.drawer_styles}
                closeIcon={null}
                open={open}
                styles={{
                    header: {
                        width: '100%',
                    },
                    body: {
                        padding: '10px',
                        width: '100%',
                    },
                }}
                height="100%"
            >
                <div className={styles.drawer_list}>
                    {navigation?.map((link, index) => (
                        <Link
                            to={link.href}
                            key={index}
                            className={styles.drawer_link}
                            onClick={closeDrawer}
                        >
                            {link.linkName}
                        </Link>
                    ))}
                    <div className={styles.drawer_clinic_contact}>
                        <div className={styles.drawer_clinic_contact_phone}>
                            <FaSquarePhone />
                            <a href={`tel:+${devision?.phone?.mobile}`}>
                                {devision?.phone?.mobile}
                            </a>
                        </div>
                        <div className={styles.drawer_clinic_contact_email}>
                            <MdEmail />
                            <a href={`mailto:${devision?.email}`}>{devision?.email}</a>
                        </div>
                    </div>
                    <div className={styles.drawer_social}>
                        <SocialBtn devision={devision} />
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default BurgerMenu;
