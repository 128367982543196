import React, { useCallback, useEffect, useState } from 'react';
import PageHead from '../../../containers/Universal/PageHead/PageHead';
import FadeBox from '../../../components/Animation/FadeBox/FadeBox';
import { scrollToTop } from '../../../utils/helpers';
import DevisionMap from '../../../components/DevisionMap/DevisionMap';
import Loader from '../../../components/Loader/Loader';
import DevisionCard from '../../../components/DevisionCard/DevisionCard';
import { apiDevisions } from '../../../axios';

const MainClinicInfo = () => {
    const [devisions, setDevisions] = useState(null);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getData({});
    }, [getData]);

    return (
        <section>
            <div className="w-full max-w-[1200px] m-auto flex flex-col">
                <p className="font-montserrat text-5xl md:text-3xl pl-4 font-semibold md:text-center text-curious-blue-700">
                    Наші філії
                </p>
                <div className="py-10 flex flex-col gap-6 md:px-4">
                    {devisions?.map((devision) => {
                        return (
                            <FadeBox key={devision?._id}>
                                <DevisionCard devision={devision} showSpec={false} />
                            </FadeBox>
                        );
                    })}
                </div>
            </div>
            {devisions !== null ? <DevisionMap devisions={devisions} /> : <Loader />}
        </section>
    );
};

export default MainClinicInfo;
