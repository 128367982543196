import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { scrollToTop } from '../../utils/helpers';
import { apiServices, apiServicesCategory } from '../../axios';
import PageHead from '../../containers/Universal/PageHead/PageHead';
import TabsService from '../../components/TabsService/TabsService';
import Loader from '../../components/Loader/Loader';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';

const Service = () => {
    const { id } = useParams();
    const [serviceCategory, setServiceCategory] = useState(null);
    const [service, setService] = useState(null);
    const [visibleText, setVisibleText] = useState(false);
    console.log('VISIBLE', visibleText);
    console.log(serviceCategory);

    const getDataCategory = useCallback(async (objectParams) => {
        try {
            const visibilityValues = ['Сімейна медицина', 'Терапія', 'Педіатрія'];
            const result = await apiServicesCategory.getServiceCategory(objectParams);

            setServiceCategory(result);

            visibilityValues?.includes(result?.categoryName) && setVisibleText(true);
        } catch (error) {
            console.log(error);
        }
    }, []);
    const getDataServices = useCallback(async (objectParams) => {
        try {
            const result = await apiServices.getServices(objectParams);
            setService(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getDataCategory(id);
        getDataServices({
            filters: {
                category: id,
            },
            limit: 400,
        });
    }, [getDataCategory, getDataServices, id]);
    return (
        <section className=" ">
            <PageHead text={serviceCategory?.categoryName} />
            <p className=" w-full max-w-[1200px] m-auto mt-10 mb-8 text-center font-montserrat font-medium text-xl italic text-curious-blue-700">
                {serviceCategory?.descr}
            </p>
            {visibleText && (
                <div className=" p-6 ls:p-4">
                    <h2 className=" font-montserrat font-medium text-curious-blue-600 text-xl text-center w-full max-w-[600px] m-auto">
                        Безкоштовні послуги надаються лише за наявності укладеної декларації з
                        лікарем даного закладу.
                    </h2>
                </div>
            )}
            <div className=" mb-20">
                {service?.length !== 0 ? <TabsService data={service} /> : <Loader />}
            </div>
            <div className=" mb-24">
                <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
            </div>
        </section>
    );
};

export default Service;
