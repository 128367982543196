import React, { useCallback, useEffect, useState } from 'react';
import { apiPartners } from '../../axios';

const Partners = () => {
    const [partner, setPartners] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiPartners.getPartners(paramsObj);
            setPartners(result);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <section>
            <h3 className="font-montserrat font-semibold text-5xl md:text-3xl text-curious-blue-600 my-10 text-center">
                Наші партнери
            </h3>
            <div className="w-full max-w-[1200px] my-20 md:my-2 flex justify-center items-center gap-10 m-auto md:flex-wrap md:gap-5">
                {partner?.map((partner) => {
                    return (
                        <a key={partner?._id} href={partner?.link} target="_blank" rel="noreferrer">
                            <img
                                src={partner?.image}
                                alt={partner?.partner}
                                className="w-full max-w-[140px] grayscale hover:grayscale-0 transition-all"
                            />
                        </a>
                    );
                })}
            </div>
        </section>
    );
};

export default Partners;
