import React, { useCallback, useEffect, useState } from 'react';
import { apiAdministration } from '../../axios';
import Loader from '../Loader/Loader';

const Administration = () => {
    const [administration, setAdministration] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiAdministration.getAdministration(paramsObj);
            setAdministration(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <section>
            <div className="w-full max-w-[1200px] p-3 m-auto py-12">
                <h1 className="font-montserrat font-semibold text-curious-blue-600 text-[44px] mb-10 text-center md:text-3xl">
                    Адміністрація
                </h1>
                <div className="flex gap-5 flex-wrap justify-center">
                    {administration.length !== 0 ? (
                        administration?.map((admin) => {
                            return (
                                <div
                                    key={admin?._id}
                                    className="w-full max-w-[350px] px-2 py-4 shadow-mt rounded-xl flex gap-3 justify-between items-start"
                                >
                                    <div className=" rounded-xl max-w-[50%] flex justify-center items-center">
                                        <img
                                            src={admin?.image}
                                            className="w-full rounded-lg max-w-[90%] h-[180px] aspect-[3/3] object-cover"
                                            alt={`${admin?.firstName} ${admin?.secondName} ${admin?.surname}`}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <p className="font-montserrat font-semibold text-curious-blue-700 text-base">
                                            {admin?.firstName}
                                            <br />
                                            {admin?.secondName}
                                            <br />
                                            {admin?.surname}
                                        </p>
                                        <p className="font-montserrat font-medium text-curious-blue-500 text-sm">
                                            {admin?.position}
                                        </p>
                                        <a
                                            className="font-montserrat font-medium text-curious-blue-500 text-sm"
                                            href={`tel:+${admin?.phone}`}
                                        >
                                            {admin?.phone}
                                        </a>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Administration;
