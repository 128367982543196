import React, { useCallback, useEffect, useState } from 'react';
import styles from './FeedbackForm.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, PhoneInput } from '../FormElement';
import { FaCommentMedical } from 'react-icons/fa';
import schema from './Tools/Schema';
import Button from '../Button/Button';
import emailSend from './Tools/FeedbackEmailSend';
import { successAllertForEmail } from '../../utils/helpers';
import { apiDevisions } from '../../axios';

const FeedbackForm = ({ modal = false, text = '' }) => {
    const [devision, setDevision] = useState(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const handleForm = (e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
    };
    const onSubmit = (data, e) => {
        emailSend(e);
        successAllertForEmail();
        reset({});
    };

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevision(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData('65e0470fea2ed03c9db26d10');
    }, [getData]);

    return (
        <div className={styles.feedback_form_wrapper}>
            {!modal ? (
                <div className={styles.feedback_title_block}>
                    <h3 className={styles.feedback_title}>{text} : </h3>
                    <a href={`tel:+${devision?.phone?.mobile}`} className={styles.feedback_phone}>
                        <span> {devision?.phone?.mobile}</span>
                    </a>
                </div>
            ) : (
                false
            )}
            <form
                className={styles.feedback_form_content}
                onSubmit={(e) => {
                    handleForm(e);
                }}
            >
                <div className={styles.feedback_form_input}>
                    <div className="max-w-[300px]">
                        <Input name={'firstName'} control={control} placeholder="Прізвище" />
                    </div>
                    <div className="max-w-[300px]">
                        <Input name={'lastName'} control={control} placeholder="Імʼя" />
                    </div>
                    <div className="max-w-[300px]">
                        <PhoneInput name={'mobile'} control={control} />
                    </div>
                </div>

                <div className={styles.feedback_form_confirm_block}>
                    <div className={styles.feedback_form_confirm_text}>
                        <FaCommentMedical />
                        <p>
                            Залишіть нам свої контактні данні, ми звʼяжемося з Вами у найближчий
                            час!
                        </p>
                    </div>
                    <Button text={'Звʼязатись з нами'} />
                </div>
            </form>
        </div>
    );
};

export default FeedbackForm;
