import React, { useCallback, useEffect, useState } from 'react';
import PageHead from '../../containers/Universal/PageHead/PageHead';
import { apiDevisions } from '../../axios';
import { scrollToTop } from '../../utils/helpers';
import DevisionCard from '../../components/DevisionCard/DevisionCard';
import Loader from '../../components/Loader/Loader';

const Devisions = () => {
    const [devisions, setDevisions] = useState([]);

    const getData = useCallback(async (paramsObjs) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObjs);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getData();
    }, [getData]);

    return (
        <section>
            <PageHead text={'Наші філії'} />
            <div className=" w-full max-w-[1200px] m-auto flex flex-col px-3 py-10">
                <p className="my-6 text-curious-blue-600 font-montserrat font-semibold text-[30px] text-center">
                    Оберіть філію
                </p>
                {devisions ? (
                    <div className=" flex justify-around items-baseline p-3 gap-5 flex-col">
                        {devisions?.map((devision) => (
                            <DevisionCard devision={devision} key={devision?._id} />
                        ))}
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default Devisions;
