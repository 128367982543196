import React, { useCallback, useEffect, useState } from 'react';
import PageHead from '../../containers/Universal/PageHead/PageHead';
import { apiNews } from '../../axios';
import { getPagination, scrollToTop } from '../../utils/helpers';
import FilterFields from '../../components/FilterFields/FilterFields';
import Loader from '../../components/Loader/Loader';
import FadeBox from '../../components/Animation/FadeBox/FadeBox';
import NewsCard from '../../components/NewsCard/NewsCard';
import PagePagination from '../../components/Pagination/PagePagination';

const News = () => {
    const [news, setNews] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState([]);

    const getData = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiNews.getNews(paramsObj);
                setNews(result);
                const count = paramsObj?.page === 1 ? await apiNews.getNewsCount(paramsObj) : 0;
                setPagination((prev) =>
                    paramsObj?.page === 1 ? getPagination(count, limit) : prev
                );
            } catch (error) {}
        },
        [limit]
    );

    useEffect(() => {
        scrollToTop();
        getData({
            limit,
            page,
            search,
        });
    }, [getData, limit, page, search]);

    return (
        <section>
            <PageHead text={'Новини'} />
            <div className="w-full max-w-[1200px] m-auto p-3">
                <FilterFields
                    placeholder="Пошук по заголовку"
                    setLimit={setLimit}
                    setSearch={setSearch}
                />
            </div>
            <div className="flex justify-center items-center flex-col">
                {news.length !== 0 ? (
                    <>
                        <div className="flex flex-wrap gap-3 justify-center items-baseline">
                            {news?.toReversed().map((news) => {
                                return (
                                    <FadeBox key={news?._id}>
                                        <NewsCard news={news} />
                                    </FadeBox>
                                );
                            })}
                        </div>
                        <div className="mt-5 my-10">
                            <PagePagination count={pagination.length} setPage={setPage} />
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default News;
