import React from 'react';
import BlockCircles from '../../../components/BlockCircles/BlockCircles';
import AnimationText from '../../../components/Animation/AnimationText/AnimationText';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const PageHead = ({ text }) => {
    return (
        <section className=" bg-curious-blue-50 h-50vh flex items-center justify-start relative overflow-hidden ls:pt-[75px]">
            <BlockCircles position="right" />
            <div className="flex flex-col gap-2 z-[5] overflow-hidden pl-20 md:pl-4 w-full max-w-[1200px] m-auto">
                <AnimationText
                    className=" font-montserrat font-semibold text-5xl text-curious-blue-900 md:text-4xl"
                    text={text}
                />
                <Breadcrumbs />
            </div>
        </section>
    );
};

export default PageHead;
