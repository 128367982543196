import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabsServicesContent from './TabsServicesContent';

const TabsService = ({ data }) => {
    const [value, setValue] = useState('1');
    const [paidServices, setPaidServices] = useState([]);
    const [freeServices, setFreeServices] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AntTab = styled(Tab)({
        font: '400 16px Montserrat',
        color: '#0395d3',
        '&.Mui-selected': {
            color: '#085b82',
            fontWeight: 600,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#0395d3',
        },
        '& .MuiTabs-indicatorSpan': {
            backgroundColor: '#0395d3',
        },
    });

    // const columns = useMemo(
    //     () => [
    //         {
    //             key: 'serviceName',
    //             children: 'Послуга',
    //         },
    //         {
    //             key: 'price',
    //             children: 'Ціна',
    //         },
    //     ],
    //     []
    // );

    // const createRowsFunction = (arr) => {
    //     return arr?.map((itm) => ({
    //         rowData: [
    //             {
    //                 key: itm?.serviceName,
    //                 children: itm?.serviceName,
    //             },
    //             {
    //                 key: itm?.price,
    //                 children: itm?.paidService ? itm?.price : 'Безоплатно',
    //                 style: {
    //                     width: 145,
    //                 },
    //             },
    //         ],
    //     }));
    // };

    useEffect(() => {
        setFreeServices(data?.filter((item) => !item?.paidService));
        setPaidServices(data?.filter((item) => item?.paidService));
        freeServices?.length !== 0 ? setValue('2') : setValue('1');
    }, [data, freeServices?.length]);

    return (
        <Box
            sx={{
                width: '100%',
                typography: 'body1',
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} centered>
                        {freeServices?.length !== 0 && <AntTab label="Безкоштовно" value="2" />}
                        {paidServices?.length !== 0 && <AntTab label="Платно" value="1" />}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TabsServicesContent services={paidServices} />
                </TabPanel>
                <TabPanel value="2">
                    <TabsServicesContent services={freeServices} />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default TabsService;
