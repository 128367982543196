import React, { useCallback, useEffect, useState } from 'react';
import PageHead from '../../containers/Universal/PageHead/PageHead';
import { apiDevisions } from '../../axios';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import FadeBox from '../../components/Animation/FadeBox/FadeBox';
import { scrollToTop } from '../../utils/helpers';
import DevisionMap from '../../components/DevisionMap/DevisionMap';
import Loader from '../../components/Loader/Loader';
import DevisionCard from '../../components/DevisionCard/DevisionCard';

const Contacts = () => {
    const [devisions, setDevisions] = useState(null);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getData({});
    }, [getData]);

    return (
        <section>
            <PageHead text={'Наші контакти'} />
            <div className="w-full max-w-[1200px] m-auto flex flex-col">
                <div className="py-10 flex flex-col gap-6">
                    {devisions?.map((devision) => {
                        return (
                            <FadeBox key={devision?._id}>
                                <DevisionCard devision={devision} showSpec={false} />
                            </FadeBox>
                        );
                    })}
                </div>
                <FadeBox className=" mb-12">
                    <FeedbackForm text="Залишіть дані для звʼязку, або зателефонуйте" />
                </FadeBox>
            </div>
            {devisions !== null ? <DevisionMap devisions={devisions} /> : <Loader />}
        </section>
    );
};

export default Contacts;
