import React, { useCallback, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { apiServices } from '../../../axios';

const ServicesSearch = () => {
    const [services, setServices] = useState([]);
    const [search, setSearch] = useState('');

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiServices.getServices(paramsObj);
            setServices(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({ limit: 10, search: search });
    }, [getData, search]);

    return (
        <section className="w-full max-w-[1200px] p-3 flex flex-col gap-4">
            <h3 className=" my-10 font-montserrat font-semibold text-4xl text-center text-curious-blue-600 md:text-3xl">
                Пошук по послугам
            </h3>
            <div className=" w-full flex gap-3">
                <div className=" relative w-full max-w-[400px] flex gap-3">
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="border-[1px] rounded-xl px-4 py-2 w-full font-light font-montserrat text-base text-curious-blue-600 outline-curious-blue-500 transition-all"
                        placeholder="Почніть писати для пошуку"
                    />
                    <FaSearch className="absolute right-3 top-3 text-curious-blue-400 text-xl opacity-50" />
                </div>
                <div
                    className="p-2 rounded-lg shadow-md cursor-pointer bg-curious-blue-500 flex justify-center items-center"
                    onClick={() => setSearch('')}
                >
                    <IoMdRefresh className="text-2xl text-white" />
                </div>
            </div>
            {services?.length !== 0 ? (
                <div className="flex flex-col gap-2">
                    {services?.map((service) => {
                        return (
                            <div
                                className="flex gap-3 items-center justify-between shadow-lg px-4 py-2 rounded-md"
                                key={service?._id}
                            >
                                <div className="flex flex-col flex-1 ">
                                    <div className="flex justify-between items-center gap-6">
                                        <p className="font-montserrat font-medium text-base text-curious-blue-500">
                                            {service?.serviceName}
                                        </p>
                                    </div>
                                    <div className="flex flex-wrap gap-3 items-center border-t-2 border-curious-blue-400 w-fit">
                                        <p className="font-semibold text-sm font-montserrat text-curious-blue-700">
                                            Категорія:
                                        </p>
                                        {service?.namesCategories?.map((category) => (
                                            <p
                                                className="font-medium text-sm font-montserrat text-curious-blue-600"
                                                key={category}
                                            >
                                                {category}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <p className="font-montserrat font-medium text-base text-curious-blue-700">
                                    {service?.price}
                                </p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="font-montserrat font-medium text-lg text-curious-blue-600">
                    Відсутні результати за пошуком
                </p>
            )}
        </section>
    );
};

export default ServicesSearch;
